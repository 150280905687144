var agt = {
  init: function() {
    agt.updateContact();
  },

  updateContact: function() {
    var el = $('#rd-submit');

    if (! el.length) return;

    el.attr("url", "https://wa.me/"+$('#contact-whatsapp').data('wa'));

    el.on('click', function() {
      var message = '';
      if (
        agt.checkValue($('#rd-nama').val()) &&
        agt.checkValue($('#rd-perusahaan').val()) &&
        agt.checkValue($('#rd-kontak').val()) &&
        agt.checkValue($('#rd-email').val()) &&
        agt.checkValue($('#rd-pesan').val())) {

        message += "?text=*Nama :* " + $('#rd-nama').val();
        message += "%0A*Perusahaan :* " + $('#rd-perusahaan').val();
        message += "%0A*Kontak :* " + $('#rd-kontak').val();
        message += "%0A*Email :* " + $('#rd-email').val();
        message += "%0A*Pesan :* " + $('#rd-pesan').val();
        $(this).attr("href", $(this).attr('url') + message);
      } else {
        alert('Data belum lengkap');
        return false;
      }
    });
  },

  checkValue: function(input) {
    return input.trim().length > 0;
  }
}

$(document).ready(function(){
  agt.init();
});
